import { NO_WHITESPACE } from '@/consts/regexp'
import { Box } from '@mui/material'
import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
  regex,
  required,
} from 'react-admin'
import { EBrand } from './types'
import TranslationInput from '@/components/TranslationInput'

const defaultValues = {
  translations: [
    {
      locale: 'EN',
      name: '',
      description: '',
    },
  ],
  isActive: true,
}

export const BrandsCreate = () => (
  <Create title="Create new Brand" redirect="list">
    <SimpleForm sanitizeEmptyValues defaultValues={defaultValues}>
      <Box
        display={{
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <TextInput
          source={EBrand.EnName}
          label="English Name"
          validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          required
          fullWidth
        />
        <TextInput
          source={EBrand.ArName}
          label="Arabic Name"
          validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          required
          fullWidth
        />

        <TranslationInput />

        <TextInput
          source={EBrand.Logo}
          label="Logo Url"
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          required
          fullWidth
        />
        <NumberInput
          source={EBrand.Order}
          label="Order"
          validate={(value) => (Number.isInteger(value) || value === null ? undefined : 'Order must be an integer')}
          fullWidth
        />
        <BooleanInput source={EBrand.IsActive} fullWidth />
        <ReferenceInput
          source={EBrand.SubCategoryId}
          reference="sub-categories"
          perPage={1000}
          sort={{ field: 'enName', order: 'ASC' }}
        >
          <SelectInput optionText={EBrand.EnName} validate={required()} fullWidth />
        </ReferenceInput>
      </Box>
    </SimpleForm>
  </Create>
)

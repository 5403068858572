import { ArrayInput, SimpleFormIterator, SelectInput, TextInput, required } from 'react-admin'
import { SUPPORTED_LOCALES } from '@/utils/constants'
import { TranslationValidation, uniqueLocale } from '@/utils/validation'

const TranslationInput = () => {
  return (
    <ArrayInput source="translations" validate={uniqueLocale}>
      <SimpleFormIterator inline>
        <SelectInput source="locale" choices={SUPPORTED_LOCALES} validate={required()} defaultValue="en" />
        <TextInput source="name" validate={TranslationValidation} />
        <TextInput source="description" multiline />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default TranslationInput

import { List, Datagrid, NumberField, TextField, BooleanField, WithRecord, ImageField, WrapperField } from 'react-admin'
import { EVariant } from '@/modules/Variants/types'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
import DateTimeField from '@/components/DateTimeField'
import { Box } from '@mui/material'

export const OfferList = () => {
  return (
    <Can I="getAllOffers" a="offers">
      <List title="Offers">
        <Datagrid rowClick={ability.can('updateOffer', 'offers') ? 'edit' : false}>
          <NumberField source={EVariant.Id} textAlign="left" />

          <TextField source="slug" sortable={false} />

          <ImageField
            source={'image'}
            sx={{
              '& img': {
                maxWidth: 50,
                maxHeight: 50,
                objectFit: 'cover !important',
                borderRadius: '50%',
              },
              display: 'flex',
              marginRight: '8px',
            }}
            sortable={false}
          />

          <WrapperField label="Languages">
            <Box style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
              <WithRecord
                render={(record) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {record.translations?.map(
                      (translation: { locale: string; title: string }) =>
                        `- ${translation.locale} ${translation.title} `,
                    )}
                  </Box>
                )}
              />
            </Box>
          </WrapperField>

          <DateTimeField source={EVariant.CreatedAt} />
          <DateTimeField source={EVariant.UpdatedAt} />
        </Datagrid>
      </List>
    </Can>
  )
}

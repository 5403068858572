import { Admin, Resource, CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'

import { appTheme } from '@/Layout/theme'
import { AdminLayout } from '@/Layout/AdminLayout'

import { ProductsList } from '@/pages/Products'
import { VariantsList } from '@/pages/Variants'
import { CostChangesList } from '@/pages/CostChanges'
import { BrandsList } from '@/pages/Brands'
import { MainCategoriesList } from '@/pages/MainCategories'
import { SubCategoriesList } from '@/pages/SubCategories'
import { OrderShow } from '@/pages/Order'
import { OrdersList } from '@/pages/Orders'
import { PromocodesList } from '@/pages/Promocodes'
import { StadiumsList } from '@/pages/Stadiums'
import { TeamsList } from '@/pages/Teams'
import { MatchesList } from '@/pages/Matches'
import { TicketsList } from '@/pages/Tickets'
import { TicketOrdersList } from '@/pages/TicketOrders'
import { TicketOrderShow } from '@/pages/TicketOrder'
import { LoginPage } from '@/pages/LoginPage'
import { MerchantsOrdersList } from '@/pages/MerchantsOrders'
import { SuppliersOrdersList } from '@/pages/SuppliersOrders'
import { ResetPassword } from '@/components/ResetPassword'
import { StadiumCreate } from '@/modules/Stadiums/StadiumsCreate'
import { StadiumsEdit } from '@/modules/Stadiums/StadiumsEdit'
import { TeamsEdit } from '@/modules/Teams/TeamsEdit'
import { TeamsCreate } from '@/modules/Teams/TeamsCreate'
import { MatchesEdit } from '@/modules/Matches/MatchesEdit'
import { MatchesCreate } from '@/modules/Matches/MatchesCreate'
import { TicketsEdit } from '@/modules/Tickets/TicketsEdit'
import { PromocodesCreate } from '@/modules/Promocodes/PromocodesCreate'
import { PromocodesEdit } from '@/modules/Promocodes/PromocodesEdit'
import { BrandsCreate } from '@/modules/Brands/BrandsCreate'
import { BrandsEdit } from '@/modules/Brands/BrandsEdit'
import { ProductsCreate } from '@/modules/Products/ProductsCreate'
import { ProductsEdit } from '@/modules/Products/ProductsEdit'
import { MainCategoriesCreate } from '@/modules/MainCategories/MainCategoriesCreate'
import { MainCategoriesEdit } from '@/modules/MainCategories/MainCategoriesEdit'
import { SubCategoriesCreate } from '@/modules/SubCategories/SubCategoriesCreate'
import { SubCategoriesEdit } from '@/modules/SubCategories/SubCategoriesEdit'
import { VariantsCreate } from '@/modules/Variants/VariantsCreate'
import { VariantsEdit } from '@/modules/Variants/VariantsEdit'

import { RolesAndPermissionsManagementList } from '@/pages/RolesAndPermissionsManagement'
import { UsersList } from '@/pages/Users'
import { AdminsList } from '@/pages/Admins'
import { AdminsCreate } from '@/modules/Admins/AdminsCreate'
import { AdminsEdit } from '@/modules/Admins/AdminsEdit'

import { authProvider } from './authProvider'
import { dataProvider } from './dataProvider'
import { Dashboard } from './Dashboard'
import { EResource } from './types'
import { RESET_PASSWORD, SETTINGS, VARIANTS_MULTI_UPLOADS } from '@/services/api/routes'
import { ERole, ERoleRequest } from '@/types/data'
import { Settings } from '@/pages/Settings'
import { ConcertsList } from '@/pages/Concerts'
import { ConcertsCreate } from '@/modules/Concerts/ConcertsCreate'
import { ConcertsEdit } from '@/modules/Concerts/ConcertsEdit'
import AllowedPages from '@/components/AllowedPages/AllowedPages'
import { CostChangesCreate } from '@/modules/CostChanges/CostChangesCreate'
import VariantsMultiUpload from '@/modules/Variants/VariantsMultiUpload'
import { MarketplaceVariantsList } from '@/pages/MarketplaceVariants'
import { MarketplaceCreate } from '@/modules/Marketplace/MarketplaceCreate'
import { MarketplaceEdit } from '@/modules/Marketplace/MarketplaceEdit'
import { ContentsList } from '@/pages/Contents'
import { ContentCreate } from '@/modules/Contents/ContentsCreate'
import { ContentEdit } from '@/modules/Contents/ContentsEdit'
import { CatalogsList } from '@/pages/Catalog'
import { CatalogsCreate } from '@/modules/Catalog/CatalogsCreate'
import { CatalogEdit } from '@/modules/Catalog/CatalogsEdit'
import { OfferList } from '@/pages/Offers'
import { OfferEdit } from '@/modules/Offers/OffersEdit'
import { OfferCreate } from '@/modules/Offers/OffersCreate'

const App = () => (
  <Admin
    loginPage={LoginPage}
    title="Digital Zone Admin"
    theme={appTheme}
    authProvider={authProvider}
    dataProvider={dataProvider}
    layout={AdminLayout}
    dashboard={Dashboard}
  >
    {(permissions) => (
      <>
        {[ERole.Admin, ERoleRequest.Admin, ERole.Superadmin].includes(permissions) ? (
          <>
            <Resource name={EResource.Variants} list={VariantsList} create={VariantsCreate} edit={VariantsEdit} />
            <Resource
              name={EResource.MarketplaceVariants}
              list={MarketplaceVariantsList}
              create={MarketplaceCreate}
              edit={MarketplaceEdit}
            />
            <Resource name={EResource.Contents} list={ContentsList} create={ContentCreate} edit={ContentEdit} />
            <Resource name={EResource.Catalogs} list={CatalogsList} create={CatalogsCreate} edit={CatalogEdit} />
            <Resource name={EResource.Offers} list={OfferList} edit={OfferEdit} create={OfferCreate} />

            <Resource name={EResource.CostChanges} list={CostChangesList} create={CostChangesCreate} />
            <Resource name={EResource.Products} list={ProductsList} create={ProductsCreate} edit={ProductsEdit} />
            <Resource name={EResource.Brands} list={BrandsList} create={BrandsCreate} edit={BrandsEdit} />
            <Resource
              name={EResource.MainCategories}
              list={MainCategoriesList}
              create={MainCategoriesCreate}
              edit={MainCategoriesEdit}
            />
            <Resource
              name={EResource.SubCategories}
              list={SubCategoriesList}
              create={SubCategoriesCreate}
              edit={SubCategoriesEdit}
            />
            <Resource name={EResource.Orders} list={OrdersList} show={OrderShow} />
            <Resource
              name={EResource.Promocodes}
              list={PromocodesList}
              create={PromocodesCreate}
              edit={PromocodesEdit}
            />
            <Resource name={EResource.Users} list={UsersList} />
            <Resource name={EResource.RolesAndPermissions} list={RolesAndPermissionsManagementList} />
            <CustomRoutes>
              <Route path={SETTINGS()} element={<Settings />} />
            </CustomRoutes>
            <Resource
              name={EResource.Admins}
              list={AdminsList}
              edit={permissions === ERole.Superadmin ? AdminsEdit : <></>}
              create={permissions === ERole.Superadmin ? AdminsCreate : <></>}
            />
          </>
        ) : null}

        {[ERole.Merchant, ERoleRequest.Merchant].includes(permissions) ? (
          <Resource name={EResource.MerchantsOrders} list={MerchantsOrdersList} />
        ) : null}
        {[ERole.Supplier, ERoleRequest.Supplier].includes(permissions) ? (
          <Resource name={EResource.SuppliersOrders} list={SuppliersOrdersList} />
        ) : null}
        {[ERoleRequest.TZadmin, ERoleRequest.Superadmin].includes(permissions) ? (
          <>
            <Resource name={EResource.Concerts} list={ConcertsList} edit={ConcertsEdit} create={ConcertsCreate} />
            <Resource name={EResource.Stadiums} list={StadiumsList} edit={StadiumsEdit} create={StadiumCreate} />
            <Resource name={EResource.Teams} list={TeamsList} edit={TeamsEdit} create={TeamsCreate} />
            <Resource name={EResource.Matches} list={MatchesList} edit={MatchesEdit} create={MatchesCreate} />
            <Resource name={EResource.Tickets} list={TicketsList} edit={TicketsEdit} />
            <Resource name={EResource.TicketOrders} list={<TicketOrdersList />} show={TicketOrderShow} />
          </>
        ) : null}

        <Resource name={EResource.AllowedPages} list={AllowedPages} />

        <CustomRoutes noLayout>
          <Route path={RESET_PASSWORD()} element={<ResetPassword />} />
        </CustomRoutes>

        <CustomRoutes>
          <Route path={VARIANTS_MULTI_UPLOADS()} element={<VariantsMultiUpload />} />
        </CustomRoutes>
      </>
    )}
  </Admin>
)

export default App

import { Box } from '@mui/material'
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  BooleanInput,
  regex,
  NumberInput,
} from 'react-admin'
import { ESubCategory } from './types'
import { EMainCategory } from '../MainCategories/types'
import { NO_WHITESPACE } from '@/consts/regexp'
import TranslationInput from '@/components/TranslationInput'

const defaultValues = {
  translations: [
    {
      locale: 'EN',
      name: '',
      description: '',
    },
  ],
}

export const SubCategoriesEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm defaultValues={defaultValues}>
      <Box
        display={{
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <TextInput
          source={ESubCategory.EnName}
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          label="English Name"
          required
          fullWidth
        />
        <TextInput
          source={ESubCategory.ArName}
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          label="Arabic Name"
          required
          fullWidth
        />
        <TranslationInput />

        <ReferenceInput source={ESubCategory.MainCategoryId} reference={'main-categories'} perPage={1000}>
          <AutocompleteInput optionText={EMainCategory.EnName} validate={required()} />
        </ReferenceInput>

        <BooleanInput source={ESubCategory.IsActive} fullWidth />
        <NumberInput
          source={ESubCategory.Order}
          label="Order"
          validate={(value) => (Number.isInteger(value) ? undefined : 'Order must be an integer')}
          fullWidth
          required
        />
      </Box>
    </SimpleForm>
  </Edit>
)

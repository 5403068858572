import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  required,
  useSimpleFormIterator,
  ButtonProps,
} from 'react-admin'
import { Button, Typography } from '@mui/material'
import { TContents } from './types'
import { SUPPORTED_LOCALES } from '@/utils/constants'

// Validation function to ensure unique locales
const uniqueLocale = (formData: TContents['translations']) => {
  const locales = formData?.map((translation) => translation.locale)
  return locales.length === new Set(locales).size
    ? undefined
    : 'Duplicate language is not allowed, please select each language once'
}

const defaultValues: Partial<TContents> = {
  translations: [
    {
      locale: 'EN',
      data: {
        banners: [{ image: '', link: '' }],
        info: { title: '', description: '' },
      },
    },
  ],
}

export const ContentCreate = () => {
  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  const handleSubmit = (values: Partial<TContents>) => {
    create(
      'contents',
      { data: values },
      {
        onSuccess: () => {
          notify('Content created successfully', { type: 'success' })
          redirect('list', 'contents')
        },
        onError: (error: unknown) => {
          const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred'
          notify(`Error: ${errorMessage}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Create title="Add Content" redirect="list">
      <SimpleForm onSubmit={handleSubmit} sanitizeEmptyValues defaultValues={defaultValues}>
        <TextInput source="slug" validate={required()} />
        <ArrayInput source="translations" fullWidth validate={uniqueLocale}>
          <SimpleFormIterator fullWidth inline addButton={<MyAddButton />}>
            <SelectInput source="locale" choices={SUPPORTED_LOCALES} validate={required()} />

            <ArrayInput source="data.banners" fullWidth>
              <SimpleFormIterator inline>
                <TextInput source="image" validate={required()} />
                <TextInput source="link" validate={required()} />
              </SimpleFormIterator>
            </ArrayInput>

            {/* Info Section */}
            <Typography variant="subtitle2" sx={{ mt: 3, mb: 1, ml: 2 }}>
              Info
            </Typography>
            <TextInput source="data.info.title" validate={required()} fullWidth sx={{ ml: 2 }} />
            <TextInput
              source="data.info.description"
              validate={required()}
              fullWidth
              multiline
              rows={4}
              sx={{ ml: 2 }}
            />

            <ArrayInput source="data.carousel" fullWidth>
              <SimpleFormIterator inline>
                <TextInput source="image" />
                <TextInput source="link" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}

const MyAddButton = (props: ButtonProps) => {
  const { add } = useSimpleFormIterator()

  return (
    <Button variant="contained" color="primary" onClick={() => add()} {...props}>
      Add Translation
    </Button>
  )
}

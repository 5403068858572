import {
  SimpleForm,
  TextInput,
  SelectInput,
  useNotify,
  useRedirect,
  required,
  ReferenceInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  useCreate,
  Create,
  useGetList,
} from 'react-admin'
import { TOffer } from './types'
import { OFFERS } from '@/services/api/routes'
import { EBrand } from '@/modules/Brands/types'
import { SUPPORTED_LOCALES } from '@/utils/constants'
import { TranslationValidation, uniqueLocale } from '@/utils/validation'

export const OfferCreate = () => {
  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  const { data: brands } = useGetList('brands', {
    pagination: { page: 1, perPage: 1000 },
  })

  const handleSubmit = (values: Partial<TOffer>) => {
    create(
      'offers',
      { data: values },
      {
        onSuccess: () => {
          notify('Offer created', {
            type: 'success',
          })
          redirect(OFFERS())
        },
        onError: (error) => {
          notify(`${error}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Create title="Add Offer" redirect="list">
      <SimpleForm onSubmit={handleSubmit} sanitizeEmptyValues>
        <TextInput source="slug" validate={required()} />
        <TextInput source="image" validate={required()} />

        <ArrayInput source="translations" validate={uniqueLocale}>
          <SimpleFormIterator inline>
            <SelectInput source="locale" choices={SUPPORTED_LOCALES} validate={required()} defaultValue="en" />
            <TextInput source="title" validate={TranslationValidation} />
            <TextInput source="description" multiline />
          </SimpleFormIterator>
        </ArrayInput>

        <SelectArrayInput
          source="offerBrands"
          choices={brands}
          optionText={(record) => `${record[EBrand.EnName]} - #${record.id}`}
          optionValue="id"
          validate={required()}
          fullWidth
          label="Brands"
        />
      </SimpleForm>
    </Create>
  )
}

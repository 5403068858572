import {
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useNotify,
  useRedirect,
  required,
  useSimpleFormIterator,
  ButtonProps,
  useUpdate,
  Edit,
} from 'react-admin'
import { Button, Typography } from '@mui/material'
import { TContents } from './types'
import { CONTENTS } from '@/services/api/routes'
import { SUPPORTED_LOCALES } from '@/utils/constants'

const uniqueLocale = (formData: TContents['translations']) => {
  const locales = formData?.map((translation) => translation.locale)
  return locales.length === new Set(locales).size
    ? undefined
    : 'Duplicate language is not allowed, please select each language once'
}

const defaultValues: Partial<TContents> = {
  translations: [
    {
      locale: 'EN',
      data: {
        banners: [{ image: '', link: '' }],
        info: { title: '', description: '' },
      },
    },
  ],
}

export const ContentEdit = () => {
  const [update] = useUpdate()
  const notify = useNotify()
  const redirect = useRedirect()

  const handleSubmit = (values: Partial<TContents>) => {
    update(
      'contents',
      { id: values.id, data: values, previousData: values },
      {
        mutationMode: 'pessimistic',
        onSuccess: () => {
          notify('Contents updated', {
            type: 'success',
          })
          redirect(CONTENTS())
        },
        onError: (error) => {
          notify(`${error}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Edit title="Edit Content" redirect="list">
      <SimpleForm onSubmit={handleSubmit} sanitizeEmptyValues defaultValues={defaultValues}>
        <TextInput source="slug" validate={required()} />
        <ArrayInput source="translations" fullWidth validate={uniqueLocale}>
          <SimpleFormIterator fullWidth inline addButton={<MyAddButton />}>
            <SelectInput source="locale" choices={SUPPORTED_LOCALES} validate={required()} />

            <ArrayInput source="data.banners" fullWidth>
              <SimpleFormIterator inline>
                <TextInput source="image" validate={required()} />
                <TextInput source="link" validate={required()} />
              </SimpleFormIterator>
            </ArrayInput>

            {/* Info Section */}
            <Typography variant="subtitle2" sx={{ mt: 3, mb: 1, ml: 2 }}>
              Info
            </Typography>
            <TextInput source="data.info.title" validate={required()} fullWidth sx={{ ml: 2 }} />
            <TextInput
              source="data.info.description"
              validate={required()}
              fullWidth
              multiline
              rows={4}
              sx={{ ml: 2 }}
            />

            <ArrayInput source="data.carousel" fullWidth>
              <SimpleFormIterator inline>
                <TextInput source="image" />
                <TextInput source="link" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

const MyAddButton = (props: ButtonProps) => {
  const { add } = useSimpleFormIterator()

  return (
    <Button variant="contained" color="primary" onClick={() => add()} {...props}>
      Add Translation
    </Button>
  )
}

import { List, Datagrid, NumberField, TextField, BooleanField, WithRecord } from 'react-admin'
import { EVariant } from '@/modules/Variants/types'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
import DateTimeField from '@/components/DateTimeField'

export const ContentsList = () => {
  return (
    <Can I="getAllContents" a="contents">
      <List title="Contents">
        <Datagrid rowClick={ability.can('updateContent', 'contents') ? 'edit' : false}>
          <NumberField source={EVariant.Id} textAlign="left" />

          <TextField source="slug" sortable={false} />

          <DateTimeField source={EVariant.CreatedAt} />
          <DateTimeField source={EVariant.UpdatedAt} />
        </Datagrid>
      </List>
    </Can>
  )
}

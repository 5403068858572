import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  required,
  ReferenceInput,
  SelectArrayInput,
  useGetList,
} from 'react-admin'
import { TCatalog } from './types'
import { EBrand } from '@/modules/Brands/types'

export const CatalogsCreate = () => {
  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  const handleSubmit = (values: Partial<TCatalog>) => {
    create(
      'catalogs',
      { data: values },
      {
        onSuccess: () => {
          notify('Catalogs created successfully', { type: 'success' })
          redirect('list', 'catalogs')
        },
        onError: (error: unknown) => {
          const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred'
          notify(`Error: ${errorMessage}`, { type: 'error' })
        },
      },
    )
  }

  const { data: mainCatagories } = useGetList('main-categories', {
    pagination: { page: 1, perPage: 1000 },
  })

  const { data: brands } = useGetList('brands', {
    pagination: { page: 1, perPage: 1000 },
  })

  return (
    <Create title="Add Catalog" redirect="list">
      <SimpleForm onSubmit={handleSubmit} sanitizeEmptyValues>
        <TextInput source="slug" validate={required()} />

        <ReferenceInput source="contentId" reference="contents" perPage={1000}>
          <SelectInput label="Contents" optionText={'slug'} validate={required()} fullWidth />
        </ReferenceInput>

        <SelectArrayInput
          source="catalogCategories"
          choices={mainCatagories}
          optionText={EBrand.EnName}
          optionValue="id"
          validate={required()}
          fullWidth
          label="Categories"
        />
        <SelectArrayInput
          source="catalogBrands"
          choices={brands}
          optionText={(record) => `${record[EBrand.EnName]} - #${record.id}`}
          optionValue="id"
          validate={required()}
          fullWidth
          label="Brands"
        />
      </SimpleForm>
    </Create>
  )
}

import { Identifier } from 'react-admin'

export const PRODUCTS = () => '/products'
export const VARIANTS = () => '/variants'
export const MARKETPLACE_VARIANTS = () => '/marketplace-variants'
export const CONTENTS = () => '/contents'
export const CATALOGS = () => '/catalogs'
export const OFFERS = () => '/offers'
export const VARIANTS_MULTI_UPLOADS = () => '/variants/multi-uploads'
export const COST_CHANGES = () => '/cost-changes'
export const POSPRODUCTS = () => '/posproducts'
export const BRANDS = () => '/brands'
export const SUBCATEGORIES = () => '/sub-categories'
export const MAINCATEGORIES = () => '/main-categories'
export const ORDERS = () => '/orders'
export const PROMOCODES = () => '/promo-codes'
export const FEES = () => '/fees'
export const SETTLEMENT = () => '/settlement'
export const STADIUMS = () => '/stadiums'
export const TEAMS = () => '/teams'
export const SEASONS = () => '/seasons'
export const ROUNDS = () => '/rounds'
export const MATCHES = () => '/matches'
export const CONCERTS = () => '/concerts'
export const TICKETS = () => '/tickets'
export const TICKETS_ID = (id: Identifier) => `/tickets/${id}`
export const TICKET_ORDERS = () => '/ticket-orders'

export const MERCHANTS_ORDERS = () => '/merchants/orders'
export const SUPPLIERS_ORDERS = () => '/suppliers/orders'

export const USERS = () => '/users'
export const ADMINS = () => '/admins'
export const SETTINGS = () => '/settings'
export const CUSTOM = () => '/custom-route'
export const ROLES_AND_PERMISSIONS = () => `/roles-and-permissions`

export const SYSTEM = () => '/system'
export const TZ = () => '/ticketzone'

export const STADIUM = () => 'dashboard/stadiums/'

export const LOGIN = () => '/login'
export const RESET_PASSWORD = () => '/resetPassword'
export const DASHBOARD = () => '/dashboard'

export const REFRESH_TOKEN_URL = () => '/auth/refresh-token'
export const AWS_UPLOAD_URL = () => '/dashboard/aws/upload-url'

export const ALLOWED_PAGES = (id: Identifier) => `/allowed-pages/${id}`

import { NO_WHITESPACE } from '@/consts/regexp'
import { Box } from '@mui/material'
import { Create, NumberInput, SimpleForm, TextInput, regex } from 'react-admin'
import { EMainCategory } from './types'
import TranslationInput from '@/components/TranslationInput'

const defaultValues = {
  translations: [
    {
      locale: 'EN',
      name: '',
      description: '',
    },
  ],
}

export const MainCategoriesCreate = () => (
  <Create title="Create new Main Category" redirect="list">
    <SimpleForm sanitizeEmptyValues defaultValues={defaultValues}>
      <Box
        display={{
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <TextInput
          source={EMainCategory.EnName}
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          label="English Name"
          required
          fullWidth
        />
        <TextInput
          source={EMainCategory.ArName}
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          label="Arabic Name"
          required
          fullWidth
        />
        <TranslationInput />

        <TextInput
          source={EMainCategory.Logo}
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          label="Logo url"
          required
          fullWidth
        />

        <NumberInput
          source={EMainCategory.Order}
          label="Order"
          validate={(value) => (Number.isInteger(value) || value === null ? undefined : 'Order must be an integer')}
          fullWidth
        />
      </Box>
    </SimpleForm>
  </Create>
)

import { NO_WHITESPACE } from '@/consts/regexp'
import { minLength, regex, required } from 'react-admin'

export const requiredTrimmed = (val: string, vals: string[]) => required()(val?.trim(), vals)
export const minLengthTrimmed = (length: number) => (val: string, vals: string[]) =>
  minLength(length)(val?.trim(), vals)

export const uniqueLocale = (translations: { locale: string }[]) => {
  const locales = translations.map((translation) => translation.locale)
  return locales.length === new Set(locales).size
    ? undefined
    : 'Duplicate language is not allowed, please select each language once'
}

export const TranslationValidation = [required(), minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]

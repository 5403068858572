import {
  SimpleForm,
  TextInput,
  SelectInput,
  useNotify,
  useRedirect,
  required,
  useSimpleFormIterator,
  ButtonProps,
  useUpdate,
  Edit,
  ReferenceInput,
  SelectArrayInput,
  useGetList,
} from 'react-admin'
import { Button } from '@mui/material'
import { TCatalog } from './types'
import { CATALOGS } from '@/services/api/routes'
import { EBrand } from '@/modules/Brands/types'

export const CatalogEdit = () => {
  const [update] = useUpdate()
  const notify = useNotify()
  const redirect = useRedirect()

  const handleSubmit = (values: Partial<TCatalog>) => {
    update(
      'catalogs',
      { id: values.id, data: values, previousData: values },
      {
        mutationMode: 'pessimistic',
        onSuccess: () => {
          notify('Catalog updated', {
            type: 'success',
          })
          redirect(CATALOGS())
        },
        onError: (error) => {
          notify(`${error}`, { type: 'error' })
        },
      },
    )
  }

  const { data: mainCatagories } = useGetList('main-categories', {
    pagination: { page: 1, perPage: 1000 },
  })

  const { data: brands } = useGetList('brands', {
    pagination: { page: 1, perPage: 1000 },
  })

  return (
    <Edit title="Edit Catalog" redirect="list">
      <SimpleForm onSubmit={handleSubmit} sanitizeEmptyValues>
        <TextInput source="slug" validate={required()} />

        <ReferenceInput source="contentId" reference="contents" perPage={1000}>
          <SelectInput label="Contents" optionText={'slug'} validate={required()} fullWidth />
        </ReferenceInput>

        <SelectArrayInput
          source="catalogCategories"
          choices={mainCatagories}
          optionText={EBrand.EnName}
          optionValue="id"
          validate={required()}
          fullWidth
          label="Categories"
        />
        <SelectArrayInput
          source="catalogBrands"
          choices={brands}
          optionText={(record) => `${record[EBrand.EnName]} - #${record.id}`}
          optionValue="id"
          validate={required()}
          fullWidth
          label="Brands"
        />
      </SimpleForm>
    </Edit>
  )
}

const MyAddButton = (props: ButtonProps) => {
  const { add } = useSimpleFormIterator()

  return (
    <Button variant="contained" color="primary" onClick={() => add()} {...props}>
      Add Translation
    </Button>
  )
}
